<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal-import"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            <button class="btn btn-link" @click="countStat()">Обновить</button>
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              x
            </button>
          </slot>
        </header>
        <div id="app" style="padding: 10px">
          <div>Количество учеников всего: {{ studentsList.length }}</div>
          <div>Количество по категориям:</div>
          <div class="tableGeneral">
            <div>
              <table class="reciept-table">
                <thead>
                  <tr>
                    <th style="max-width: 180px">Категория</th>
                    <th>Количество</th>
                    <th>Ученики</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(cat, index) in category" :key="index">
                    <td>{{ cat.Category }}</td>
                    <td>{{ cat.count }}</td>
                    <td>
                      <ul v-for="(student, index) in cat.student" :key="index">
                        <li>{{ student }}</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <br />
          </div>
        </div>
        <div>
          <table class="reciept-table">
            <thead>
              <tr>
                <th>Класс</th>
                <th v-for="(cat, index) in category" :key="index">
                  {{ cat.Category }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(clas, index) in classList" :key="index">
                <td>
                  {{ clas.className }}
                </td>
                <td v-for="(cat, index) in clas.cat" :key="index">
                  {{ cat }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.tableGeneral {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

li {
  list-style-type: none; /* Убираем маркеры */
  text-align: left;
  font-size: 12px;
  line-height: 0.6;
}
ul {
  margin: 4px;
  margin-left: 0; /* Отступ слева в браузере IE и Opera */
  padding-left: 0; /* Отступ слева в браузере Firefox, Safari, Chrome */
}

TH {
  background: #b0e0e6; /* Цвет фона */
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>

<script>
import TutorialDataService from "../services/TutorialDataService";

export default {
  props: ["classList", "studentsList"],
  data() {
    return {
      files: [],
      progress: 0,
      category: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },

    countStat() {
      console.log("LOG", this.studentsList);
      console.log(this.classList);
      //this.classList.sort((a, b) => (a.className > b.classPrint ? 1 : -1));
      for (let j = 0; j < this.category.length; j++) {
        this.$set(this.category[j], "count", 0);
        this.$set(this.category[j], "student", []);
      }
      for (let k = 0; k < this.classList.length; k++) {
        this.$set(this.classList[k], "cat", []);
      }
      for (let k = 0; k < this.classList.length; k++) {
        for (let j = 0; j < this.category.length; j++) {
          this.classList[k].cat[j] = 0;
        }
      }

      for (let i = 0; i < this.studentsList.length; i++) {
        if (this.studentsList[i].Category != undefined) {
          for (let j = 0; j < this.category.length; j++) {
            if (this.category[j].Category === this.studentsList[i].Category) {
              this.$set(this.category[j], "count", this.category[j].count + 1);
              for (let k = 0; k < this.classList.length; k++)
                if (
                  this.studentsList[i].classID === this.classList[k].classID
                ) {
                  this.classList[k].cat[j] = this.classList[k].cat[j] + 1;
                  this.category[j].student.push(
                    this.classList[k].className +
                      " " +
                      this.studentsList[i].FirstName +
                      " " +
                      this.studentsList[i].LastName
                  );
                }
              this.category[j].student.sort((a, b) => (a > b ? 1 : -1));
            }
          }
        }
      }

      console.log(this.category);
    },

    async retrieveCategory() {
      await TutorialDataService.getCategory()
        .then((response) => {
          this.category = response.data.map(this.getDisplayCategory);
          this.countStat();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayCategory(data) {
      return {
        Category: data.cat,
      };
    },
  },

  mounted() {
    // this.$nextTick(function () {
    //   console.log("awdawd");
    //   this.retrieveCategory();
    //   this.countStat();
    // });
  },
};
</script>

<style>
.table_view {
  padding-bottom: 10px;
  padding-top: 10px;
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  max-height: 340px;

  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
}
label {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
}

.table_view_students_import th,
td {
  padding: 0px auto 0px auto;
  text-align: left;
}

.v-row-group__header td {
  background-color: #9370db;
  color: white;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-import {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 450px;

  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
}

.modal-header,
.modal-footer {
  padding: 1px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
}

.modal-body {
  position: relative;
  padding: 2px 10px;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
  padding: 2px;
}
</style>
